import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import ReactPlayer from 'react-player'
//import './App.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/icofont/icofont.min.css'
import './assets/vendor/academicons/css/academicons.min.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import './assets/vendor/venobox/venobox.css'
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import './assets/css/style.css'
import infantKick from './assets/img/kicking.png'
import chessXAI from './assets/img/chessXAI.PNG'
import IROS2021_img from "./assets/img/iros20212.png"
import IROS_22 from "./assets/img/iros2022.png"
import KDDI_img from "./assets/img/activityrecog.png"
import ICAPS_GIF from "./assets/videos/GIF_Website_ICAPS.mp4"
import profile from './assets/img/profile.png'
import CV from './assets/files/CV_Devleena_Das_2024.pdf'
import C0 from './assets/img/robo_animation/C0.jpg'
import C1 from './assets/img/robo_animation/C1.jpg'
import C2 from './assets/img/robo_animation/C2.jpg'
import C3 from './assets/img/robo_animation/C3.jpg'
import C4 from './assets/img/robo_animation/C4.jpg'
import ICML_img from './assets/img/ICML_overview.png'
import DEFT_diag from './assets/img/DEFT_diag.png'
import S2E_img from "./assets/img/S2E_pic.png"

function App() {
  //const [chessXAICite,setchessXAICite] = useState(false)
  //const [InfantCite, setInfantCite] = useState(false)
  const [count, setCount] = useState(0)
  const [images, setImages] = useState([
    C0, C1, C2, C3, C4])

    useEffect(() => {
      const interval = setInterval(() => {
          let tempCount = 0
          if(count < images.length -1){
              tempCount = count + 1
          }
          else{
              tempCount = 0
          }
          setCount(tempCount)
      },1000)
      return () => {
          clearInterval(interval)
      }
  },[count])

  return (
    <div className="App">
      {/* <!-- ======= Mobile nav toggle button ======= --> */}
      <button type="button" class="mobile-nav-toggle d-xl-none"><i class="icofont-navigation-menu"></i></button>

      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex flex-column justify-content-center">

        <nav class="nav-menu">
          <ul>
            <li class="active"><a href="#hero"><i class="bx bx-home"></i> <span>Home</span></a></li>
            <li><a href="#about"><i class="bx bx-user"></i> <span>About</span></a></li>
            <li><a href="#portfolio"><i class="bx bx-book-content"></i> <span>Research</span></a></li>
          </ul>
        </nav>
        {/* <!-- .nav-menu --> */}

      </header>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" class="d-flex flex-column justify-content-center">
        <div class="container" data-aos="zoom-in" data-aos-delay="100">
          <h1>Devleena Das</h1>
          <div class="social-links">
            <a href="https://www.linkedin.com/in/ddevleena/" target="_blank" rel="noopener noreferrer" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            <a href="https://scholar.google.com/citations?user=xzt3VP0AAAAJ&hl=en" target="_blank" rel="noopener noreferrer" class="google-scholar"><i class="ai ai-google-scholar"></i></a>
            {/* <a href="https://github.com/ddevleena" target="_blank" rel="noopener noreferrer" class='github'><i class="bx bxl-github"></i></a> */}
          </div>
          {/* <a href={Resume} target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-download"> Download Resume</a> */}
          <a href={CV} target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-download"> Download CV</a>

        </div>     
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">

        {/* <!-- ======= About Section ======= --> */}
        <section id="about" class="about">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>About</h2>
            </div>

            <div class="row">
              <div class="col-3 px-4">
                <img src={profile} class="img-fluid" alt=""/>
              </div>
              <div class="col-9 content">
                <h3>Applied AI Scientist & AI Consultant </h3>
                <p class="lead">

                  Currently, I am an Applied AI Research Scientist within Georgia Tech’s Office of Executive VP of Research (EVPR) Team, 
                  where I am developing Gen-AI powered solutions that enhance academic advising and tutoring experiences. 
                  Additionally, I leverage my expertise as a part-time AI consultant for Scale AI, providing technical guidance to build and enhance Large Language Models (LLMs).
                  <br/> <br/>
                  Previously, I contributed to large-scale AI applications at IBM Research, Sony AI and Accenture Labs. 
                  At IBM Research, I worked on AI for scientific discovery, specifically leveraging pre-trained language models to generate novel protein sequences. 
                  At Sony AI, I developed a tree-based algorithm to analyze a deep RL agent, GT Sophy. 
                  At Accenture Labs, I've worked on improving the real-world scalability of LLM fine-tuning by developing data-efficient fine-tuning approaches. 
                  <br/> <br/>
                  During my PhD, I built computational AI frameworks that improve explainability and user understanding of complex AI models. Specifically, I developed techniques that extract meaningful representations from AI models to ground explanations of AI decision making into high-level concepts and semantic knowledge. My research has been applied in the domains of robotics, smart-home systems and game-based reinforcement learning systems. 
                  My developed techniques have proven successful in both improving AI learning as well as improving human understanding.
                  <br/> <br/>
                  {/* I'm a recent PhD graduate from the <a href="http://rail.gatech.edu/" target="_blank" rel="noopener noreferrer">RAIL Lab</a>, advised by <a href="https://www.cc.gatech.edu/~chernova/" target="_blank" rel="noopener noreferrer">Dr. Sonia Chernova</a>. */}

                </p>
              </div>
            </div>

          </div>
        </section>
        {/* <!-- ======= Portfolio Section ======= --> */}
        <section id="portfolio" class="portfolio section-bg">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Selected Publications</h2>
            </div>

            <div class="row my-5">
                <div class="col-sm text-center">
                    <img style={{height:"150px"}} src={DEFT_diag} class="img-fluid pr-3" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5>DEFT: Data Efficient Fine-Tuning for Large Language Models via Unsupervised Core-Set Selection </h5>          
                <p>
                By <b>Devleena Das</b> and Vivek Khetan <br/>
                <i>Under Review</i> <br/> <br/>  <span style={{color:"#2471A3"}}>Work contributed as part of an internship at Accenture Labs - AI Team</span></p>  
               
                <a href="https://arxiv.org/abs/2310.16776" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Preprint</a>
              </div>
            </div>

            <div class="row my-5">
              <div class="col-sm text-center">
              <img style={{height:"230px"}} src={S2E_img} class="img-fluid pr-2" alt="Responsive image"/>
                </div>
            <div class="col-sm">
                <h5> State2Explanation:Concept Based Explanations to Benefit Agent Learning and User Understanding   </h5>   
                <p>
                  By <b>Devleena Das</b>, Sonia Chernova, Been Kim<br/>
                  <i>In 37th Conference on Neural Information Processing Systems (NeurIPS), 2023</i> </p> 
                  <a href="https://arxiv.org/abs/2309.12482" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
            </div>

            <div class="row my-5">
                <div class="col-sm text-center">
                    <img style={{height:"350px"}} src={ICML_img} class="img-fluid pr-3" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5>Reprogramming Pretrained Language Models for Antibody Sequence Infilling </h5>          
                <p>
                By Igor Melnyk, Vijil Chenthamarakshan, Pin-Yu Chen, Payel Das, Amit Dhurandhar, Inkit Padhi, <b>Devleena Das</b> <br/>
                <i>In 40th International Conference on Machine Learning (ICML), 2023</i> <br/> <br/>  <span style={{color:"#2471A3"}}>Work contributed as part of an internship at IBM Research - AI Science</span></p>  
               
                <a href="http://proceedings.mlr.press/v202/melnyk23a/melnyk23a.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
            </div>

            <div class="row my-5">
              <div class="col-sm text-center">
                <ReactPlayer 
                    url = {ICAPS_GIF} 
                    controls={true} 
                    width="100%" 
                    height= "100%"
                    loop = {true} 
                    onStart = {true}
                    // onEnded={() => setVideoFinished(true)}
                  />
                </div>

                <div class="col-sm">
                <h5> Subgoal-Based Explanations for Unreliable Decision Support Systems </h5>          
                <p>
                  By <b>Devleena Das</b>, Been Kim, and Sonia Chernova<br/>
                  <i>In 28th International Conference on Intelligent User Interfaces (IUI), 2023</i> </p> 
                  <a href="https://dl.acm.org/doi/abs/10.1145/3581641.3584055" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
              </div>

              <div class="row my-5">
                <div class="col-sm text-center">
                    <img style={{height:"230px"}} src={KDDI_img} class="img-fluid pr-3" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5>Explainable Activity Recognition for Smart Home Systems </h5>          
                <p>
                  By <b>Devleena Das</b>, Yasutaka Nishimura, Rajan P. Vivek, Naoto Takeda, Sean T. Fish, Thomas Ploetz, and Sonia Chernova<br/>
                  <i>In ACM Transactions on Interactive Intelligent Systems (TiiS), Volume 13, Issue 2, 2023</i> </p> 
                <a href="https://dl.acm.org/doi/full/10.1145/3561533" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
              </div>

              <div class="row my-5">
                <div class="col-sm text-center">
                    <img style={{height:"250px"}} src={IROS_22} class="img-fluid pr-3" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5>Explainable Knowledge Graph Embedding: Inference Reconciliation for Knowledge Inferences Supporting Robot Actions </h5>          
                <p>
                  By Angel Daruna, <b>Devleena Das</b>, and Sonia Chernova<br/>
                  <i>In 2022 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS '22)</i> </p> 
                <a href="https://ieeexplore.ieee.org/document/9982104" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
                {/* <button onClick= {()=>setRoboXAICite(!RoboXAICite)}class="btn btn-outline-dark btn-paper btn-sm"> Bibtex</button> */}
              </div>
              </div>

            <div class="row my-5">
              <div class="col-sm text-center">
              <img style={{height:"230px"}}src={IROS2021_img} class="img-fluid pr-3" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5> Semantic-Based Explainable AI: Leveraging Semantic Scene Graphs and Pairwise Ranking to Explain Robot Failures</h5>          
                <p>
                  By <b>Devleena Das</b> and Sonia Chernova<br/>
                  <i>In 2021 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS '21)</i> </p> 
                <a href="https://ieeexplore.ieee.org/abstract/document/9635890" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
                <a href="https://youtu.be/mnMG7fM70hU" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Oral Presentation</a>
                {/* <button onClick= {()=>setRoboXAICite(!RoboXAICite)}class="btn btn-outline-dark btn-paper btn-sm"> Bibtex</button> */}
              </div>
              </div>

            <div class="row my-5">
              <div class="col-sm text-center">
                  <img 
                  style={{height:"230px"}}
                  src={images[count]}
                  class="img-fluid" 
                  alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5> Explainable AI for Robot Failures: Generating Explanations that Improve User Assistance in Fault Recovery</h5>          
                <p>
                  By <b>Devleena Das</b>, Siddhartha Banerjee, and Sonia Chernova<br/>
                  <i>In 2021 ACM/IEEE International Conference on Human-Robot Interaction (HRI '21)</i><span style={{color:"#C0504D"}}><b> (Best Paper - Technical Advances Track)</b></span> </p> 
                <a href="https://dl.acm.org/doi/abs/10.1145/3434073.3444657" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
                <a href="https://youtu.be/QHk7ubeZPfs" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Oral Presentation</a>
              </div>
              </div>

            <div class="row my-5">
              <div class="col-sm text-center">
                  <img style={{height:"230px"}}src={chessXAI} class="img-fluid" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5> Leveraging Rationales to Improve Human Task Performance</h5>          
                <p>
                  By <b>Devleena Das</b>, and Sonia Chernova<br/>
                  <i>In 25th International Conference on Intelligent User Interfaces (IUI), 2020</i> </p>
                <a href="https://dl.acm.org/doi/abs/10.1145/3377325.3377512" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
              </div>

            <div class="row my-5 ">
              <div class="col-sm text-center">
                  <img style={{height:"180px"}} src={infantKick} class="img-fluid" alt="Responsive image"/>
                </div>
                <div class="col-sm">
                <h5>Vision-Based Detection of Simultaneous Kicking for Identifying Movement Characteristics for Infants At-Risk for Neuro-Disorders</h5>          
                <p>
                  By <b>Devleena Das</b>, Katelyn Fry and Ayanna Howard<br/>
                  <i>In 17th International Conference on Machine Learning and Applications (ICMLA), 2018</i> </p>    
                <a href="https://ieeexplore.ieee.org/document/8614255" target="_blank" rel="noopener noreferrer" class="btn btn-outline-dark btn-paper btn-sm"> Paper</a>
              </div>
              </div>

        </div>     
        </section>
        {/* <!-- End Portfolio Section --> */}

      </main>
  </div>
  );
}

export default App;
